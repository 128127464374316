import storageKey from "@/config/storageKey"

export default {
    namespaced: true,
    state: () => ({
        userInfo: {
            id: localStorage.getItem('id')
        }
    }),
    mutations: {
        saveUser(state, user) {
            if (user.sign) {
                localStorage.setItem(storageKey.TOKEN, user.sign)
            }
            state.userInfo = user
        },
        clearUser(state) {
            localStorage.removeItem(storageKey.USERID)
            localStorage.removeItem(storageKey.TOKEN)
            state.userInfo = {}
        },
    }
}